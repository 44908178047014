






import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import { getVendor } from '../store/rest.service';
import { Vendor } from '../store/models';

@Component
export default class VendorHeadingComponent extends BaseComponent {

  @Prop() public id: any;
  @Prop() public route: any;
  @Prop() public vendorTypeDescription: any;

  private vendorLoading: boolean = false;
  private editedItem?: Vendor|null = null;

  protected mounted() {
    this.getVendor();
  }

  private getVendor() {
    this.vendorLoading = true;
    getVendor(this.id).then((response) => {
      this.editedItem = response;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.vendorLoading = false;
    });
  }
}
