



























import { Component } from 'vue-property-decorator';
import NestedNavComponent from '../components/NestedNavComponent.vue';
import VendorHeadingComponent from '../components/VendorHeadingComponent.vue';
import BaseComponent from '../components/BaseComponent';
import { MenuMeta } from '../store/models';
import * as features from '../lib/feature-constants';

@Component({
  components: {
    NestedNavComponent,
    VendorHeadingComponent,
  },
})
export default class ClientSummaryNav extends BaseComponent {

  protected baseRoute: string = '/clients/id/';
  protected feature: string = features.clientMenu;

  private refreshHeader: number = 0;

  private nestedMenu: MenuMeta[] = [
    {
      title: 'Client Overview',
      href: '',
    },
    {
      title: 'Projects',
      href: '/projects',
    },
  ];

  private clientActions: any = null;

  protected mounted() {
    this.baseRoute += this.$route.params.id;
  }

  private navigate(code) {
  }

  private getReportLink(): string {
    return '';
    // return '/reports/project?project=' + this.$route.params.id;
  }
}
